import axios from "axios";
import { DailyLearningAnswer } from '@/types/DailyLearningAnswer'
import { DailyLearning } from '@/types/DailyLearning'
import { DailyLearningReport } from "@/types/daily_learning_report";
import { DailyLearningUserReport } from "@/types/daily_learning_user_report";
export type GetDateParams = {
  start_date: string,
  end_date: string
}

export class DailyLearningRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = DailyLearningRepository.DOMAIN + "v1/projects/" + this.projectId + "/daily_learnings/";
  }


  async getList(): Promise<DailyLearningAnswer[]> {
    return axios.get(this.url + "history", {}).then((response) => {
      return response.data.learning_course_answers;
    });
  }
  async getToday(): Promise<DailyLearning> {
    return axios.get(this.url + "today", {}).then((response) => {
      return response.data;
    });
  }
  async getReport(params: GetDateParams): Promise<DailyLearningReport[]> {
    return axios.get(this.url + "report", { params: params }).then((response) => {
      return response.data.daily_report;
    });
  }
  async getUserReport(params: GetDateParams): Promise<DailyLearningUserReport[]> {
    return axios.get(this.url + "user_report", { params: params }).then((response) => {
      return response.data.daily_user_report;
    });
  }
}

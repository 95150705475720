<template>
  <el-card class="project-box">
    <DailyLearnings
      :project_id="project_id"
      :daily_learning_answers="dailLearningAnswers"
      :daily_learning="dailyLearning"
    />
  </el-card>
</template>

<script>
// @ is an alias to /src
import DailyLearnings from "@/components/learning/DailyLearnings.vue";
import { DailyLearningLogic } from "@/logic/learning/DailyLearningLogic";
// import { DailyLearningAnswer } from "@/types/DailyLearningAnswer";
import { useRoute } from 'vue-router'
import { computed, watch, onMounted } from "vue";
export default {

  name: "DailyLearning",
  components: {
    DailyLearnings
  },
  setup() {

    const { dailyLearningStateReturn, getHistory, getToday } = DailyLearningLogic();
    const route = useRoute()
    const project_id = computed(() => Number(route.params.project_id))
    watch(() => route.params.project_id, () => {
      if (route.params.project_id) {
        getHistory(project_id.value);
        getToday(project_id.value)
      }
    })

    onMounted(() => {
      getHistory(project_id.value)
      getToday(project_id.value)
    });
    return {
      project_id,
      ...dailyLearningStateReturn()
    }
  }

};
</script>
<style scoped>
.list {
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  text-align: center;
}
</style>
import { ref, watch } from "vue";
import { ElMessage } from "element-plus"
import axios from 'axios';
import { DailyLearningAnswer } from "@/types/DailyLearningAnswer";
import { DailyLearning } from "@/types/DailyLearning";
import { DailyLearningReport } from "@/types/daily_learning_report";
import { DailyLearningUserReport } from "@/types/daily_learning_user_report";
import { DailyLearningRepository, GetDateParams } from '@/repositories/DailyLearningRepository'
import { useRoute } from 'vue-router'
export const DailyLearningLogic = () => {

    const dailyLearningErrors = ref("");
    const dailyLearningLoading = ref(false)
    const dailLearningAnswers = ref<DailyLearningAnswer[]>([])
    const dailyLearning = ref<DailyLearning | null>()
    const dailyLearningReports = ref<DailyLearningReport[]>([])
    const dailyLearningUserReports = ref<DailyLearningUserReport[]>([])
    const route = useRoute();

    const getHistory = async (projectId: number) => {
        dailyLearningLoading.value = true

        const repository: DailyLearningRepository = new DailyLearningRepository(projectId);
        try {
            dailLearningAnswers.value = await repository.getList();

            dailyLearningLoading.value = false;

        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dailyLearningErrors.value = `取得に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
            }
        }


    };

    const getToday = async (projectId: number) => {
        dailyLearningLoading.value = true

        const repository: DailyLearningRepository = new DailyLearningRepository(projectId);
        try {
            dailyLearning.value = await repository.getToday();

            dailyLearningLoading.value = false;

        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dailyLearningErrors.value = `取得に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
            }
        }

    };
    const getReport = async (params?: GetDateParams) => {
        if (params == undefined) {
            params = { start_date: "", end_date: "" }
        }
        dailyLearningLoading.value = true
        const projectId = Number(route.params.project_id);

        const repository: DailyLearningRepository = new DailyLearningRepository(projectId);
        try {
            dailyLearningReports.value = await repository.getReport(params);

            dailyLearningLoading.value = false;

        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dailyLearningErrors.value = `取得に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
            }
        }

    };
    const getUserReport = async (params?: GetDateParams) => {
        if (params == undefined) {
            params = { start_date: "", end_date: "" }
        }
        dailyLearningLoading.value = true
        const projectId = Number(route.params.project_id);

        const repository: DailyLearningRepository = new DailyLearningRepository(projectId);
        try {
            dailyLearningUserReports.value = await repository.getUserReport(params);

            dailyLearningLoading.value = false;

        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dailyLearningErrors.value = `取得に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
            }
        }

    };
    const dailyLearningStateReturn = () => {
        return {
            dailyLearningErrors,
            dailyLearningLoading,
            dailyLearning,
            dailLearningAnswers,
            dailyLearningReports,
            dailyLearningUserReports,
            getHistory,
            getToday,
            getReport,
            getUserReport

        }
    }
    watch(dailyLearningErrors, () => {
        if (dailyLearningErrors.value == "") {
            return;
        }
        ElMessage({
            showClose: true,
            message: dailyLearningErrors.value,
            type: 'error'
        });
        dailyLearningErrors.value = "";
    })
    return {
        dailyLearningStateReturn,
        ...dailyLearningStateReturn()
    }
}


